import React from "react"
import { graphql } from "gatsby"
import Container from "../components/container"
import GraphQLErrorList from "../components/graphql-error-list"
import SEO from "../components/seo"
import Layout from "../containers/layout"
import RenderSections from "../components/RenderSections"

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  console.log(data.content.openGraphImage.asset.fixed.src)
  return (
    <Layout>
      <SEO
        title={data.content.title}
        description={data.content.description}
        image={data.content.openGraphImage.asset.fixed.src}
      />
      <Container>
        <h1 hidden>Welcome to {site.title}</h1>
        {data && <RenderSections sections={data.content.sections} />}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    content: sanityPage(title: { eq: "Home" }) {
      title
      description
      openGraphImage {
        asset {
          fixed(width: 1200) {
            src
          }
        }
      }
      sections {
        ... on SanityCenteredAccordion {
          _key
          _type
          title
        }
        ... on SanityCenteredHero {
          _key
          _type
          title
        }
        ... on SanityStaticLogoSection {
          _key
          _type
          title
          subtitle
        }
        ... on SanityTeamSection {
          _key
          _type
          heading
          label
          team {
            name
            bioNew
            image {
              asset {
                fluid(maxWidth: 350) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanityBlogSectionList {
          _key
          _type
          heading
          label
          blog {
            publishedAt
            id
            title
            categories {
              title
            }
            slug {
              current
            }
            excerptNew
            _rawExcerpt(resolveReferences: { maxDepth: 10 })
            mainImage {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
              alt
            }
          }
        }
        ... on SanityTwoCol {
          _key
          _type
          pageOne {
            title
            description
       
            openGraphImage {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          pageTwo {
            title
            description
         
            openGraphImage {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanitySplitHero {
          _key
          _type
          label
          titleWhite
          titleColor
          description
          backgroundImage {
            alt
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanitySplitCtaImage {
          _key
          _type
          blogPost {
            slug {
              current
            }
            title
            mainImage {
              asset {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
            categories {
              title
            }
            excerptNew
          }
        }
        ... on SanityLogoGrid {
          _key
          _type
          heading
          logo {
            _key
            alt
            asset {
              fluid(maxWidth: 200) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityProjectEstimate {
          _key
          _type
          title
        }
        ... on SanityOffSetGrid {
          _key
          _type
          heading
          detailsOne {
            description
            heading
            _key
          }
        }
        ... on SanityTwoColByTwoRow {
          _key
          _type
          descriptionOne
          descriptionTwo
          detailsOne {
            heading
            description
            _key
          }
          detailsTwo {
            _key
            heading
            description
          }
          headingOne
          headingTwo
          imageOne {
            _key
            _type
            alt
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
          imageTwo {
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          subtitle
        }
        ... on SanityJustifiedCta {
          _key
          _type
          titleBlack
          button {
            _key
            _type
            title
            route {
              slug {
                current
              }
              page {
                title
              }
            }
          }
        }
        ... on SanitySimpleCta {
          _key
          _type
          title
        }
        ... on SanitySideBySideImage {
          _key
          _type
          heading
          description
          image {
            alt
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
          details {
            heading
            description
            _key
          }
        }
        ... on SanityTitleAndSubtitle {
          _type
          title
          subtitle
        }
        ... on SanitySideBySideImageLeft {
          _key
          _type
          description
          heading
          details {
            heading
            description
          }
          image {
            _key
            alt
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityLogoSplit {
          _key
          _type
          description
          heading
          button {
            route {
              slug {
                current
              }
            }
            title
          }
          logo {
            _key
            alt
            asset {
              fluid(maxWidth: 200) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityFourColSection {
          _key
          _type
          label
          title
          subtitle
          details {
            _key
            heading
            description
          }
        }
      }
    }
  }
`

export default IndexPage
